/* Food Item Button */
.button {
  background-color: #D0AE64;
  color: rgba(243, 237, 187, 0.815);
  width: 50%;
  border: #D0AE64 1px solid;
  border-radius: 7px;
  padding: 5px;
}

.button:hover{
  color: white;
  transition: 0.2s ease-in;
}

/* Active Menu */
.active_menu_tab {
  background-color: #272525;
}
  
.bg-custom-active {
  background-color: #272525;
}

.home-container {
  position: relative;
}

/* Banner Styling */

.banner-image {
  background-size: cover;
  background-position: center center;
  background-image: url('../../../assets/banner.jpg');
  width: 100%;
  height: 100vh;
  position: relative; /* Ensure inner elements are positioned relative to this container */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 85px;
  overflow-x: hidden;
}

.banner-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 46, 46, 0.435); /* Adjust the opacity here */
  z-index: 1; /* Ensure this covers the background image but stays behind other content */
}

.banner-text {
  color: white;
  animation: fadeInLeft 1.5s ease-in-out;
  text-align: center;
  padding: 0 1.2em;
}

.banner-text h2 {
  font-size: 2.4em; /* Adjust the size as needed */
  margin: 0;
}

.button-container button {
  padding: 0.4em 0.8em; /* Adjust padding to fit content */
  width: auto; /* Ensure width fits the content */
}

.button-container a {
  text-decoration: none; /* Remove text-decoration */
  display: block; /* Make link a block-level element */
  width: 100%; /* Make link take full width of the button */
  height: 100%; /* Make link take full height of the button */
  text-align: center; /* Center text */
  padding: 0.5em 1em; /* Adjust padding to match the button */
  text-decoration: none; /* Remove text-decoration */
  line-height: normal; /* Ensure proper line height */
  box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .banner-text h2 {
    font-size: 1.3em; /* Adjust the size for smaller screens */
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Menu Background */
.menu-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(rgba(12, 12, 12, 0.990), rgba(12, 12, 12, 0.990)), 
  url('../../../assets/foodpattern.jpg'); */
  background: #F6F5F8;
  background-size: cover;
  z-index: -2;
  /* background: #1e1e1e; */
}